@use 'font';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import '../../../../node_modules/saturn-datepicker/theming';

// vodafone red #E60000

$md-scspalette: (
    50 : #e1eef7,
    100 : #b4d5eb,
    200 : #82badd,
    300 : #4f9ecf,
    400 : #2a89c5,
    500 : #0474bb,
    600 : #036cb5,
    700 : #0361ac,
    800 : #0257a4,
    900 : #014496,
    A100 : #c1d8ff,
    A200 : #8eb9ff,
    A400 : #5b99ff,
    A700 : #4189ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-accentpalette: (
    50 : #f3f3f3,
    100 : #e1e1e1,
    200 : #cdcecd,
    300 : #b9bab9,
    400 : #aaabaa,
    500 : #9b9c9b,
    600 : #939493,
    700 : #898a89,
    800 : #7f807f,
    900 : #6d6e6d,
    A100 : #effdef,
    A200 : #c1f8c1,
    A400 : #87ff87,
    A700 : #6eff6e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// yarn
$scs-dashboard-primary: mat-palette($md-scspalette, 500, 300, 900);
$scs-dashboard-accent: mat-palette($md-accentpalette);

// The warn palette is optional (defaults to red).
$scs-dashboard-warn: mat-palette($mat-red);

$hover_color: mat-color($scs-dashboard-primary);

// Create the theme object (a Sass map containing all of the palettes).
$scs-dashboard-theme: mat-light-theme($scs-dashboard-primary, $scs-dashboard-accent, $scs-dashboard-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($scs-dashboard-theme);
@include sat-datepicker-theme($scs-dashboard-theme);

.primary_color {
    border-color: mat-color($scs-dashboard-primary);
}

:root {
    --primary-color: mat-color($scs-dashboard-primary);
  } // we can also define variables with JavaScript
