/* You can add global styles to this file, and also import other style files */
@import './angularTheme.scs.scss';
@import './defaults.scss';
@import './defaultAlignments.scss';

//Global setting
body {
  background-color: $lightGrey;
}

footer {
  font-size: 12px;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

p {
  text-align: justify;
}

.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button {
  border-radius: $borderRadius !important;
}

.mat-button.mat-cancel:hover {
  background-color: transparent !important;
}

.mat-button.mat-cancel,
.mat-stroked-button.mat-cancel,
.mat-raised-button.mat-cancel,
.mat-flat-button.mat-cancel {
  background-color: #ffffff;
  color: $blue;
  padding-left: 0px;
}

.mat-raised-button.mat-back,
.mat-flat-button.mat-back {
  background-color: #ffffff;
  color: $blue;
}

.mpCalendarEvent {
  background-color: $blue !important;
}

.mat-button-toggle {
  font-size: 0.8rem;
  line-height: 1rem !important;

  .mat-button-toggle-label-content {
    line-height: 1rem !important;
  }

  &.mat-button-toggle-checked {
    background: $blue;
    color: #fff !important;
  }
}

.mat-button-focus-overlay {
  background-color: transparent!important;
}

mat-accordion mat-expansion-panel-header .mat-content {
  overflow: visible !important;
}

.ng-animating mat-accordion mat-expansion-panel {
  mat-expansion-panel-header {
    height: 48px !important;
  }

  div.mat-expansion-panel-content {
    height: 0px !important;
    visibility: hidden !important;
  }
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: $blue;
}

.mat-icon {
  fill: grey;
}

.mat-icon.mat-white {
  color: white;
}

.mat-step-icon .mat-icon {
  color: white;
}

mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: $formFieldPadding !important;
  }

  .mat-form-field-subscript-wrapper {
    top: calc(100% - 0.8rem) !important;
  }

  .mat-form-field-infix {
    padding: $formFieldPadding !important;
    box-sizing: border-box;
    border-top: none !important;
  }

  &.mat-form-field-disabled {
    .mat-form-field-prefix .mat-icon {
      opacity: 0.3;
    }
  }

  &.mat-form-field-type-mat-select {
    .mat-form-field-flex {
      padding: $formFieldPadding;
      margin: 0;
      background: transparent;
      border: 2px solid $lightGrey;
    }

    .mat-form-field-wrapper {
      padding: 0 !important;
    }
  }

  .mat-form-field-flex {
    align-items: center;
    margin-top: $formFieldPadding;
    background-color: #f1f1f1;
    border-radius: $borderRadius;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    padding-left: $formFieldPadding;
  }

  .mat-form-field-suffix mat-icon {
    color: #cac6c6;
    padding-right: 5px;
  }
}

body * {
  font-family: 'open-sans';
}

h1,
h2,
h3,
h4 {
  font-family: 'open-sans';
}

.mat-tab-group,
.mat-tab-label,
.mat-tab-link {
  font-family: 'open-sans';
}

// mat tab content - hide scrollbar
.mat-tab-body.mat-tab-body-active {
  overflow-y: hidden !important;
}

.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4 {
  font-family: 'open-sans';
}

a {
  text-decoration: none;
  transition: color 0.2s;
  color: $blue;

  &.active {
    color: $blue;
  }
}

mat-divider.mat-divider-horizontal {
  margin: 0.5rem 0;
}

mat-card {
  border-radius: 15x;
}

.multiSearchAutocompleteOption {
  height: auto !important;
  line-height: auto !important;

  .mat-option-text {
    display: inline-flex;
    flex-direction: column;
    line-height: initial !important;
    margin: 0.5rem 0;
  }

  span.parkignLotName {
    font-size: 80%;
  }
}

#overlayingSidebarContainer {
  width: 15vw !important;
}

.snackbar_color {
  background: #32ffc2;
}

.snackbar_color_ps_ws {
  background: #ffc011;
  color: black;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

$primary-color: $hover_color;
$back-color: $hover_color;

#button_color {
  background-color: $hover_color;
  color: white;
}

mat-dialog-container {
  border-radius: 20px !important;
}

/**
* Heatmap styling
*/

/*button styling*/
.mat-raised-button.mat-heatmap {
  background-color: #ffffff;
  color: #4a4d4e; // vodafone-grey

  &:hover {
    background-color: $blue;
    color: #ffffff; // vodafone-red
  }
}

.mat-raised-button.selected {
  background-color: $blue;
  color: #ffffff; // vodafone-red
}

/*button - group a*/
.mat-raised-button.mat-groupA {
  background-color: #ffffff;
  color: #a79d9d;

  &:hover {
    background-color: #0474bb;
    color: #ffffff; // vodafone-red
  }
}

.mat-raised-button.groupA-selected {
  background-color: #0474bb;
  color: #ffffff; // vodafone-red
}

/*button - group b*/
.mat-raised-button.mat-groupB {
  background-color: #ffffff;
  color: #a79d9d;

  &:hover {
    background-color: #dd3632;
    color: #ffffff; // vodafone-red
  }
}

.mat-raised-button.groupB-selected {
  background-color: #dd3632;
  color: #ffffff; // vodafone-red
}

.coloring-1 {
  color: #0474bb;
}

.coloring-2 {
  color: #dd3632;
}

.background-coloring-1 {
  background-color: #0474bb;
}

.background-coloring-2 {
  background-color: #dd3632;
}
